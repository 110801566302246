<!--
用途：市、区、街道、社区的级联选择
传参： orgCode:'44', //默认为44,即广东省开始查起
       resetNum:0 , //默认为0，改变了就会触发清空的方法，一般用于重置使用，让resetNum自增1即可
       isClear:false // 默认为false，是否显示清空按钮
       orgLimit:''  // 默认为空，限制当前传入的orgCode，只显示orgCode相等的数据
暴露出去的方法： changeCasader ，选择了每一项之后的数据

-->

<template>
  <div>
       <Cascader @on-clear="changeClear" @on-change="changeCasder" transfer :load-data="loadData" change-on-select :style=" 'margin-right:' + right + 'px;' +  'width:' + width + 'px'" :data="CascaderList" :clearable="isClear" v-model="CascaderId" :placeholder="orgCode == '44' ?'请选择市/区/街道/社区':'请选择'"></Cascader>
  </div>
</template>

<script>
export default {
    props:{
        orgCode:{
            type:String,
            default:()=>{
                return '44'
            }
        },
        resetNum:0,
        isClear:{
            type:Boolean,
            default:()=>{
                return false
            }
        },
        orgLimit:{
            type:String,
            default:()=>{
                return ''
            }
        },
        width:{
            type:String,
            default:()=>{
                return 350
            }
        },
        right:{
            type:String,
            default:()=>{
                return 10
            }
        }
    },
    data(){
        return{
            // 存放选择的社区
            CascaderList:[],
            CascaderId:[],
            isOnce:true,
        }
    },
    created(){
        this.getCascader()
    },
    methods:{
        // 点击清除按钮事件
        changeClear(){
           this.CascaderId = []
           this.changeCasder(this.CascaderId)
        },
         // 获取级联
        getCascader(){
            this.$get({url:'/orgzz/pc/organization/selectCascadeDataScope',data:{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:this.orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            }}).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                    let data = []
                    if(this.orgLimit && this.orgLimit != ''){
                        res.dataList.map(item =>{
                            if(item.orgCode.search(this.orgLimit) != -1){
                                data.push(item)
                            }
                        })
                    }
                    if(data.length) res.dataList = data
                    this.CascaderList = this.forMatterTree(res.dataList)
                    if(this.CascaderList.length != 0){
                        if(this.resetNum == 0){
                            this.CascaderId.push(this.CascaderList[0].value)
                            this.isOnce = false
                            this.changeCasder(this.CascaderId)
                        }         
                    }
                    this.CascaderList.map(item =>{
                        item.loading = false
                    })
                }
            })
        },
        changeCasder(val,item){
                this.$emit('changeCasader',val)
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName;
                data[i].value = data[i].orgCode;
                if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
                    this.forMatterTree(data[i].children);
                }
            }
            return data;
        },
       async loadData (item, callback) {
            item.loading = true
            let params = {
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:item.orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            };
           await this.$get({url:'/orgzz/pc/organization/selectCascadeDataScope',data:params
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items =>{
                        if(items.orgLevel == 4){
                            items.loading = false
                        }else if(items.orgLevel == 3){
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children);
                     if(this.resetNum == 0){
                         this.CascaderId.push(arr[0].value)
                         this.changeCasder(this.CascaderId)
                    }
                }else{
                     callback([]);
                }

            })
        },
    },
    watch:{
        resetNum(val){
                this.CascaderId = []
        }
    }
}
</script>

<style>

</style>